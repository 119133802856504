import React, { useContext } from 'react';

import { LocalizationContext } from '../../utils/context';
import { UA } from '../../utils/constants';
import iconFacebook from '../../assets/images/icon-facebook.svg';
import iconInstagram from '../../assets/images/icon-instagram.svg';
import termsOfUseUA from '../../assets/files/terms_of_use_ua.pdf';
import termsOfUseEN from '../../assets/files/terms_of_use_en.pdf';
import privacyPolicy from '../../assets/files/privacy_policy.pdf';
import publicOfferUA from '../../assets/files/public_offer_ua.pdf';
import publicOfferEN from '../../assets/files/public_offer_en.pdf';

const Footer = () => {
  const strings = useContext(LocalizationContext);
  const isUALanguage = window.localStorage.getItem('language').includes(UA);

  return (
    <footer>
      <div className="footer">
        <div className="container">
          <div className="footer--col">
            <p className="footer--title">{strings.address}</p>
            <p>{strings.address1},</p>
            <p>{strings.address2}.</p>
            <p>{strings.edrpou} 44731135</p>

            <p className="footer--title">{strings.phoneNumber} <span>+38 073 223 31 20</span></p>
          </div>

          <div className="footer--col">
            <p className="footer--title">{strings.email}</p>
            <p><a href="mailto:cf.united.ua@gmail.com">cf.united.ua@gmail.com</a></p>

            <p className="footer--title">{strings.followUs}</p>
            <ul className="footer--socials">
              <li>
                <a href="https://www.facebook.com/charity.fund.united" target="_blank" rel="noreferrer">
                  <img src={iconFacebook} alt="Facebook" />
                </a>
              </li>
              <li>
                <a href="https://instagram.com/charity_fund_united" target="_blank" rel="noreferrer">
                  <img src={iconInstagram} alt="Instagram" />
                </a>
              </li>
            </ul>
          </div>

          <div className="footer--col">
            <p className="footer--link">
              <a href={isUALanguage ? termsOfUseUA : termsOfUseEN} target="_blank" rel="noreferrer">{strings.termsOfUse}</a>
            </p>
            <p className="footer--link">
              <a href={privacyPolicy} target="_blank" rel="noreferrer">{strings.privacyPolicy}</a>
            </p>
            <p className="footer--link">
              <a href={isUALanguage ? publicOfferUA : publicOfferEN} target="_blank" rel="noreferrer">{strings.publicOffer}</a>
            </p>
          </div>
        </div>
      </div>

      <div className="subfooter">
        <div className="container">
          <span>&copy; 2022-2024 {strings.cfUnited} «<span className="uppercase">{strings.united}</span>»</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
