import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    united: 'United',
    cfUnited: 'CO Charity fund',
    description1: 'Frankivsk IT specialists, together with Kharkiv volunteers, joined the collection of donations in support of Ukraine and organized their charitable fund to help the battalions of the Armed Forces.',
    description2: 'for our defenders',
    ourGoal: 'Our goal',
    donate: 'Donate',
    requestHelp: 'Request for help',
    uah: 'UAH',
    ifaks: 'IFAKs',
    ifaks1: 'IFAKs',
    moneyCollected: 'funds raised',
    deliveredToFront: 'delivered',
    reportsOnFb: 'All reports on Facebook',
    followUs: 'Follow us',
    phoneNumber: 'Phone number',
    copied: 'Copied',
    address: 'Address',
    email: 'Email',
    transfersInUkraine: 'WITHIN UKRAINE',
    transfersFromAbroad: 'SWIFT',
    purposeOfPayment1: 'Purpose of payment',
    purposeOfPayment2: '«Charity»',
    payWithCard: 'By card',
    requestForHelp: 'Request for help',
    donation: 'Donation',
    formValidationTexts: {
      nameTooShort: 'Name is too short',
      nameTooLong: 'Name is too long',
      required: 'Required',
      phoneNotValid: 'Phone number is not valid',
      commentTooShort: 'Message is too short',
      commentTooLong: 'Message is too long',
      emailIsInvalid: 'Invalid email'
    },
    formFields: {
      name: 'Name',
      phone: 'Phone',
      email: 'Email',
      comment: 'Message',
      commentPlaceholder: 'Briefly describe your need',
      sendRequest: 'Send request'
    },
    edrpou: 'EDRPOU',
    mono: 'Send to Mono',
    privat24: 'Privat24',
    mfo: 'Bank\'s code (MFO)',
    account: 'Account (IBAN)',
    address1: 'Ukraine, Ivano-Frankivsk, v. Uhornyky',
    address2: 's. Siechenova 129a, building 25 room 45',
    paths: {
      home: 'Home'
    },
    supportWithMoney: 'Support with funds',
    accountInMono: 'Account in Monobank',
    accountInPrivat: 'Account in PrivatBank',
    termsOfUse: 'Terms of use',
    privacyPolicy: 'Privacy policy',
    publicOffer: 'Public offer agreement',
    sendMoney: 'Donate',
    safeTransfer: 'Safe transfer',
    wfpDisclaimer1: '*WAYFORPAY charges the exchange rate difference between the market and official currency rates ➞ in UAH from the sender. That is, the overall sum of the transfer will be ~6% higher when transferring money to the fund’s account.',
    wfpDisclaimer2: 'Please take this into consideration during the transfer.',
    crypto: 'Crypto',
    user: 'User',
    kredoBank: 'KredoBank',
    privatBank: 'PrivatBank',
    recipient: 'Recipient',
    additionalCorrespondentBanks: 'Additional Correspondent banks',
    manual: {
      title: 'Tactical medicine manual',
      subtitle: 'Protocol M.A.R.C.H.',
      subtitleSuffix: 'By Ruslan Pihota',
      footerNote: 'CF "UNITED"',
      openInPDF: 'Open in PDF',
      slides: {
        orderOfActions: 'The order of actions in case of injury',
        massiveBleeding: '“M” – Massive bleeding',
        airways: '“A” – Airways',
        respiration: '“R” – Respiration',
        circulation: '“C” – Circulation',
        hipotermia: '“H” – Hipotermia'
      }
    }
  },
  ua: {
    united: 'Згуртовані',
    cfUnited: 'БО Благодійний фонд',
    description1: 'Франківські IT-спеціалісти спільно з харківськими волонтерами долучилися до збору пожертв на підтримку України та організували свій благодійний фонд для допомоги батальйонам ЗСУ.',
    description2: 'для наших воїнів',
    ourGoal: 'Наша ціль',
    donate: 'Підтримати',
    requestHelp: 'Звернутись по допомогу',
    uah: 'грн',
    ifaks: 'аптечок',
    ifaks1: 'аптечок',
    moneyCollected: 'зібрали коштів',
    deliveredToFront: 'передали на фронт',
    reportsOnFb: 'Всі звіти в Facebook',
    followUs: 'Слідкуйте за нами',
    phoneNumber: 'Номер телефону',
    copied: 'Скопійовано',
    address: 'Юридична адреса',
    email: 'Email',
    transfersInUkraine: 'ПО УКРАЇНІ',
    transfersFromAbroad: 'SWIFT',
    purposeOfPayment1: 'Призначення платежу',
    purposeOfPayment2: '«Благодійна допомога»',
    payWithCard: 'Карткою',
    requestForHelp: 'Звернутись по допомогу',
    donation: 'Благодійний внесок',
    formValidationTexts: {
      nameTooShort: 'Ім\'я занадто коротке',
      nameTooLong: 'Ім\'я занадто довге',
      required: 'Обов\'язково',
      phoneNotValid: 'Номер невірний',
      commentTooShort: 'Повідомлення занадто коротке',
      commentTooLong: 'Повідомлення занадто довге',
      emailIsInvalid: 'Email невірний'
    },
    formFields: {
      name: 'Ім\'я',
      phone: 'Номер телефону',
      email: 'Email',
      comment: 'Коментар',
      commentPlaceholder: 'Коротко опишіть вашу потребу',
      sendRequest: 'Надіслати запит'
    },
    edrpou: 'ЄДРПОУ',
    mono: 'Поповнити банку',
    privat24: 'Приват24',
    mfo: 'Код банку (МФО)',
    account: 'Рахунок (IBAN)',
    address1: 'Україна, м. Івано-Франківськ, с. Угорники',
    address2: 'вул. Сєченова 129А, корпус 25 приміщення 45',
    paths: {
      home: 'Головна'
    },
    supportWithMoney: 'Підтримка коштами',
    accountInMono: 'Банка в Monobank',
    accountInPrivat: 'Рахунок в ПриватБанку',
    termsOfUse: 'Правила використання',
    privacyPolicy: 'Правила приватності',
    publicOffer: 'Договір публічної оферти',
    sendMoney: 'Надіслати кошти',
    safeTransfer: 'Безпечний переказ',
    wfpDisclaimer1: '*WAYFORPAY знімає курсову різницю між ринковим та офіційним курсами валюта ➞ гривня з відправника. Тобто, сума переказу буде на ~6% більша при переказі на користь фонду.',
    wfpDisclaimer2: 'Врахуйте це, будь ласка, при переказі.',
    crypto: 'Crypto',
    user: 'Користувач',
    kredoBank: 'Кредобанк',
    privatBank: 'ПриватБанк',
    recipient: 'Отримувач',
    additionalCorrespondentBanks: 'Додаткові банки-кореспонденти',
    manual: {
      title: 'Посібник з тактичної медицини',
      subtitle: 'Протокол M.A.R.C.H.',
      subtitleSuffix: 'Ілюстрації воїна Руслана Піхоти',
      footerNote: 'БО "БФ "ЗГУРТОВАНІ"',
      openInPDF: 'Відкрити посібник у PDF',
      slides: {
        orderOfActions: 'Порядок дій при пораненні',
        massiveBleeding: '“M” – Масивна кровотеча',
        airways: '“A” – Дихальні шляхи',
        respiration: '“R” – Ураження легень',
        circulation: '“C” – Циркуляція крові',
        hipotermia: '“H” – Гіпотермія'
      }
    }
  }
});
